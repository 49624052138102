@import "../config"
@import "/styles/config"

.about-development
    padding: 10px 0
    margin: 60px 0 120px 0
    overflow-x: hidden
    +max-width($mobail)
        margin: 40px 0 60px

    &Container
        position: relative
        +max-width($xl-tablet)
            display: block


    &Header
        font: 700 48px/120% $font
        margin-bottom: 40px
        +max-width($tablet)
            font: 700 36px/120% $font
        +max-width($mobail)
            font: 700 24px/140% $font
            margin-bottom: 20px


    &Slider
        position: initial


    &Item
        display: flex !important
        flex-direction: column
        gap: 20px
        position: relative


    &Title
        font: 400 30px/42px $font
        +max-width($mobail)
            font: 400 20px/28px $font

        &Span
            position: absolute
            left: -20px
            font: 700 30px/42px $font
            +max-width($mobail)
                left: -12px
                font: 700 20px/28px $font

        span
            color: $link
            font: 700 30px/42px $font
            +max-width($mobail)
                font: 700 20px/28px $font


    &Inner
        display: flex
        gap: 20px
        justify-content: space-between
        +max-width($mobail)
            flex-direction: column


    &Info
        display: flex
        flex-direction: column


    &Author
        font: 600 22px/150% $font
        +max-width($mobail)
            font: 600 14px/140% $font


    &Position
        font: 400 18px/140% $font
        color: #8F939C
        +max-width($tablet)
            font: 400 16px/150% $font
        +max-width($mobail)
            font: 400 12px/125% $font


    &Icon
        +max-width($mobail)
            max-width: 148px


//Переопределение стилей слайдера
.about-developmentSlider


//slick-list
.about-developmentSlider :global(.slick-list)
    overflow: visible
    margin-left: -80px
    +max-width($xl-tablet)
        //overflow: hidden
        margin-left: 0

//slick-slide
.about-developmentSlider > div > div > div
    width: 1080px
    padding: 0 80px
    opacity: 0.3
    +max-width($xl-tablet)
        width: 290px
        opacity: 0
        padding: 0
    +max-width($mobail)
        width: 290px
        padding: 0


//slick-prev
.about-developmentSlider > button
    right: 72px
    top: -100px
    left: auto
    +max-width($tablet)
        top: -5.75rem
    +max-width($mobail)
        right: 40px
        top: -52px


//slick-next
.about-developmentSlider > button:last-child
    right: 0
    top: -75px
    +max-width($tablet)
        top: -4.2rem
    +max-width($mobail)
        top: -36px


//disabled
.about-developmentSlider > button:disabled
    border: 2px solid #D4DAE5 !important

    &:hover
        background: transparent !important

    svg
        path
            stroke: #D4DAE5 !important


//before
.about-developmentSlider > button:before
    content: none

//current
.about-developmentSlider :global(.slick-current)
    opacity: 1


#arrow-left, #arrow-right
    width: 52px
    height: 52px

    border-radius: 50%
    border: 2px solid $link
    transition: all 0.3s ease
    +max-width($mobail)
        width: 32px
        height: 32px

    svg
        width: 6px
        height: 12px
        margin: 0 auto

        path
            stroke: #000000


    &:hover
        background: $link

        svg
            path
                stroke: #ffffff

#arrow-left
    transform: rotate(180deg)


.about-developmentSlider :global(.slick-disabled)
    border: 2px solid #D4DAE5 !important

    &:hover
        background: transparent !important

    svg
        path
            stroke: #D4DAE5 !important
