@import "../config"
@import "/styles/config"


.clients
    position: relative
    margin: 0 0 120px 0
    z-index: 1
    +max-width($mobail)
        margin: 0 0 60px 0


    &__title
        font: 700 48px/120% $font
        margin: 0 0 40px 0
        +max-width($lg-tablet)
            margin: 0 0 24px 0
            font: 700 36px/120% $font
        +max-width($mobail)
            margin: 0 0 12px 0
            font: 700 24px/140% $font

    &__rating
        display: flex
        margin: 0 0 20px 0
        font: 400 18px/140% $font
        +max-width($lg-tablet)
            font: 400 16px/150% $font
        +max-width($mobail)
            font: 400 12px/125% $font


        &-text
            margin: 0 0 0 4px
            color: #566369

        &-place
            color: #FF5107


    &__wrapper
        position: relative


    &__grid
        position: relative
        display: flex
        flex-direction: column
        +max-width($sm-mobail)



    &__row
        display: flex
        flex-direction: column
        margin-bottom: 60px


        &:last-child
            margin-bottom: 0


    &__name
        margin-bottom: 20px
        font: 600 30px/150% $font
        letter-spacing: 1px
        +max-width($lg-tablet)
            font: 600 28px/150% $font
        +max-width($mobail)
            font: 600 20px/140% $font

    &__content
        display: flex
        flex-wrap: wrap
        column-gap: 20px
        +max-width($lg-tablet)
            column-gap: 0


    &__item
        position: relative
        display: block
        margin: 0 -20px 20px -20px
        width: (100%/4)
        min-height: 166px
        +max-width($lg-tablet)
            width: (100%/3)
            min-height: 120px
            margin: 0 0 10px 0
        +max-width($mobail)
            width: (100%/2)
            min-height: 80px
            display: flex
            align-items: center
            justify-content: center
            margin: 0


    &__link
        position: relative
        display: flex
        width: 100%
        height: 100%
        align-items: center
        justify-content: center
        transition: opacity .3s ease
        padding: 0 20px
        +max-width($mobail)
            padding: 0
            height: 100%

        &:hover
            transition: all	0.3s ease
            box-shadow: 0 4px 11px rgba(26, 46, 94, 0.13)
            +max-width($mobail)
                box-shadow: none



    &__wrapper + &__btn
        margin: 60px 0 0 0
        +max-width($mobail)
            margin: 32px 0 0 0



    &__btn
        position: relative
        display: block
        width: 100%
        margin: 20px 0 0 0




