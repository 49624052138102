@import "../config"
@import "/styles/config"

.clients

    &__item
        position: relative
        display: block
        margin: 0 -20px 20px -20px
        width: (100%/4)
        min-height: 166px
        +max-width($lg-tablet)
            width: (100%/3)
            min-height: 120px
            margin: 0 0 10px 0
        +max-width($mobail)
            width: (100%/2)
            min-height: 80px
            display: flex
            align-items: center
            justify-content: center
            margin: 0


    &__link
        position: relative
        display: flex
        width: 100%
        height: 100%
        align-items: center
        justify-content: center
        transition: opacity .3s ease
        padding: 0 20px
        +max-width($mobail)
            padding: 0
            height: 100%

        &:hover
            transition: all	0.3s ease
            box-shadow: 0 4px 11px rgba(26, 46, 94, 0.13)
            +max-width($mobail)
                box-shadow: none